<template>
	<div class="text-xs-center ma-5">
		<span :class="classes">
			<slot></slot>
		</span>
	</div>
</template>

<style scoped>
.underline {
	border-bottom: 5px solid #39d4b9;
}
</style>

<script>
export default {
	props: {
		light: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			classes: {
				underline: true,
				'display-1': true,
				'font-weight-bold': true,
				'text-xs-center': true,
				'texto--text': !this.light,
				'white--text': this.light
			}
		}
	}
}
</script>
