<template>
	<v-container>
		<vg-titulo>NOSSOS DIFERENCIAIS</vg-titulo>
		<v-container grid-list-xl fluid>
			<v-layout row wrap justify-center>
				<v-flex xs12 sm4 lg3 xl2 v-for="(card, i) in cards" :key="i" d-flex>
					<v-card color="transparent" flat>
						<v-card-text class="text-xs-center texto--text">
							<img :src="card.icon" height="70" />
							<div class="subheading my-3 font-weight-bold">{{card.titulo}}</div>
							<p class="justificado">{{card.desc}}</p>
							<div v-if="card.titulo2">
								<div class="subheading my-3 font-weight-bold">{{card.titulo2}}</div>
								<p class="justificado">{{card.desc2}}</p>
							</div>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-layout justify-center>
			<v-img src="/images/devices-botijao.png" max-width="800" class="mt-5"></v-img>
		</v-layout>
		<v-layout justify-center>
			<v-btn block color="accent" class="headline green-btn" :href="`https://api.whatsapp.com/send?phone=5561998911670&text=Tenho%20uma%20revenda%20de%20gás%20e%20água%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20sistema!`" target="_blank" round large>Quero saber mais</v-btn>
		</v-layout>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			cards: [
				{
					icon: '/images/icons/icones-verdes-1.svg',
					titulo: 'Sistema inovador, completo e fácil de usar',
					desc: 'O vendergas foi desenvolvido pensado para tornar o uso simples e fácil e facilitar o dia a dia dos vendedores, atendentes e dos donos das revendas de gás e água. No vendergás você encontra suas informações em um clique, uma vez que o sistema possui um menu de fácil utilização.'
				},
				{
					icon: '/images/icons/icones-verdes-3.svg',
					titulo: 'Aumente a produtividade da sua revenda sem complicação',
					desc: 'Em vez de perder tempo anotando seus pedidos e tentando fechar o caixa de sua empresa com planilhas, aumente a produtividade de sua revenda e conquiste novos clientes. O vendergás é o sistema feito para você revendedor de gás e água controlar a sua revenda de maneira fácil e rápida sem complicação.'
				},
				{
					icon: '/images/icons/icones-verdes-4.svg',
					titulo: 'Monitore e controle sua revenda de onde estiver',
					desc: 'O vendergás possui o que há de mais moderno em gerenciamento de informações. Com o vendergás você terá suas informações precisas e em tempo real de suas vendas, pedidos, estoque e muito mais pelo celular, tablet e computador a qualquer momento, tudo isso em gráficos em dashboard inovador com informações precisas na palma da mão.'
				},
				{
					icon: '/images/icons/icones-verdes-5.svg',
					titulo: 'Tecnologia de ponta 100% cloud com acesso Web',
					desc: 'Sistema online, com suas informações seguras e disponíveis a qualquer momento e em qualquer lugar.',
					titulo2: 'Sistema 100% desenvolvido para revendas de gás e água',
					desc2: 'O vendergás foi 100% pensado e desenvolvido para revendas de Gás e Água.'
				}
			]
		}
	}
}
</script>