<template>
	<div>
		<v-img src="/images/bg-contato.png" class="white--text py-5" height="700">
			<v-container fill-height grid-list-xl>
				<v-layout row wrap align-center justify-center>
					<v-flex xs12 md5>
						<div class="display-1 font-weight-thin hidden-md-and-up" v-html="titulo"></div>
						<div class="display-3 font-weight-thin hidden-sm-and-down" v-html="titulo"></div>
						<p class="headline mt-3">{{ 'o sistema feito para você revendedor de gás e água controlar a sua revenda de maneira fácil e rápida sem complicação.' | caixaAlta }}</p>
						<!-- <div class="headline">
							<p>A SOLUÇÃO EM GERENCIAR GÁS</p>
							<p>
								UM <strong>APP DO SEU JEITO</strong>,<br />
								COM A <strong>CARA DA SUA REVENDA</strong>,<br />
								PARA QUE VOCÊ E SEUS CLIENTES<br />
								TENHAM MUITO MAIS <strong>COMODIDADE!</strong>
							</p>
						</div> -->
						<v-btn block color="accent" class="headline green-btn" :href="`https://api.whatsapp.com/send?phone=5561998911670&text=Tenho%20uma%20revenda%20de%20gás%20e%20água%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20sistema!`" target="_blank" round large>Conhecer o sistema</v-btn>
					</v-flex>
					<v-flex xs12 md7>
						<v-img src="/images/devices-botijao.png" max-height="500" max-width="100%" contain></v-img>
					</v-flex>
				</v-layout>
			</v-container>
		</v-img>
		<v-sheet color="accent" height="20"></v-sheet>
	</div>
</template>

<script>
export default {
	data(){
		return {
			titulo: 'SISTEMA PARA <strong>REVENDAS DE GÁS E ÁGUA</strong>'
		}
	}
}
</script>


