<template>
	<v-container grid-list-xl>
		<vg-titulo>O SISTEMA</vg-titulo>
		<v-layout row align-center class="my-5">
			<v-flex xs2>
				<v-img src="/images/icons/sistema-celular.svg" contain height="100"></v-img>
			</v-flex>
			<v-flex xs10>
				<div class="title texto--text">Gestão eficiente para você e sua revenda</div>
				<p class="texto--text">Sistema online, com suas informações seguras e disponíveis a qualquer momento e em qualquer lugar. No Vendergás você encontra suas informações em um clique, de maneira fácil e rápida sem complicação.</p>
			</v-flex>
		</v-layout>
		<v-layout row wrap justify-center>
			<v-flex xs12 sm4 lg3 xl2 v-for="(card, i) in cards" :key="i" d-flex>
				<v-card color="bgDark" class="round-right" dark>
					<v-card-text class="text-xs-center">
						<img :src="card.icon" height="70" />
						<div class="subheading my-3 font-weight-bold">{{card.titulo}}</div>
						<p>{{card.desc}}</p>
					</v-card-text>
				</v-card>
			</v-flex>
		</v-layout>
		<v-layout justify-center>
			<v-img src="/images/vendergas-telas.png" max-width="800" class="mt-5"></v-img>
		</v-layout>
		<v-layout justify-center>
			<v-btn block color="accent" class="headline green-btn" :href="`https://api.whatsapp.com/send?phone=5561998911670&text=Tenho%20uma%20revenda%20de%20gás%20e%20água%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20sistema!`" target="_blank" round large>Adquirir o sistema</v-btn>
		</v-layout>
	</v-container>
</template>

<style scoped>
.round-right {
	border-top-right-radius: 70px;
	box-shadow: 0 10px 0 #39d4b9;
}
</style>

<script>
export default {
	data() {
		return {
			cards: [
				{
					icon: '/images/icons/icon-card-1.svg',
					titulo: 'Dashboard de gestão da sua revenda',
					desc: 'Informações precisas e em tempo real de suas vendas, pedidos e seu estoque pelo celular, tablet e computador a qualquer momento.'
				},
				{
					icon: '/images/icons/icon-card-2.svg',
					titulo: 'Aplicativo próprio e exclusivo para a sua revenda',
					desc: 'Com sua marca, cor e logo. Os novos clientes e pedidos do seu aplicativo irão diretamente para o sistema.'
				},
				{
					icon: '/images/icons/icon-card-3.svg',
					titulo: 'Aplicativo para entregador',
					desc: 'Envie os pedidos com apenas um clique direto para o celular do seu entregador em melhores condições e reduza o tempo de entrega.'
				},
				{
					icon: '/images/icons/icon-card-4.svg',
					titulo: 'Controle seu estoque de onde estiver',
					desc: 'Monitore as movimentações de estoque em tempo real do seu celular, computador de onde estiver e controle todos os produtos da sua revenda e programe seus abastecimentos.'
				},
				{
					icon: '/images/icons/icon-card-5.svg',
					titulo: 'Fechamento de caixa diário',
					desc: 'De fácil usabilidade em menu próprio com todos os relatórios necessários.'
				},
				{
					icon: '/images/icons/icon-card-6.svg',
					titulo: 'Controle da portaria',
					desc: 'Área de pedido exclusivo para portaria.'
				},
				{
					icon: '/images/icons/icon-card-7.svg',
					titulo: 'Disque gás e água',
					desc: 'Teleatendimento com Bina.'
				},
				{
					icon: '/images/icons/icon-card-8.svg',
					titulo: 'Emissão de NFe, NFCe e CTE, Emissão de boletos bancários',
					desc: ''
				},
				{
					icon: '/images/icons/icon-card-11.svg',
					titulo: 'Relatórios de preço médio e Relatório de previsão de compra do cliente',
					desc: ''
				},
				{
					icon: '/images/icons/icon-card-9.svg',
					titulo: 'Financeiro',
					desc: 'Contas a pagar e a receber, demonstrativo de resultados (DRE).'
				},
				{
					icon: '/images/icons/icon-card-12.svg',
					titulo: 'Funcionalidade de localização do entregador mais próximo',
					desc: 'Após a confirmação do pedido você pode repassar para o aplicativo do entregador mais próximo da entrega.'
				},
				{
					icon: '/images/icons/icon-card-13.svg',
					titulo: 'Rastreador',
					desc: 'Localização em tempo real dos seus entregadores.'
				},
				{
					icon: '/images/icons/icon-card-15.svg',
					titulo: 'Controle completo sobre suas revendas',
					desc: 'Mais de 50 relatórios disponíveis.'
				},
				{
					icon: '/images/icons/icon-card-16.svg',
					titulo: 'Aumento de produtividade',
					desc: 'Sistema rápido e fácil de usar.'
				},
				{
					icon: '/images/icons/icon-card-3.svg',
					desc: 'Aplicativo para emissão de NFCe e Nfe na rua.',
					titulo: 'Aplicativo para emissão de notas'
				},
				{
					icon: '/images/icons/icon-card-13.svg',
					desc: 'Saiba onde estao seus pontos fortes e fracos de venda.',
					titulo: 'Mapa de calor das vendas'
				}
			]
		}
	},
	mounted() {
		this.cards.sort((a, b) => a.desc.length > b.desc.length ? -1 : 1)
	}
}
</script>
