<template>
	<v-app>
		<v-navigation-drawer v-model="drawer" app disable-resize-watcher>
			<v-list>
				<div v-for="(item, i) in menu" :key="i">
					<v-list-tile @click="goTo(item.goTo)">
						<v-list-tile-content>{{item.label}}</v-list-tile-content>
					</v-list-tile>
					<v-divider></v-divider>
				</div>
				<v-list-tile href="https://app.vendergas.com.br/login" target="_blank">
					<v-list-tile-content>Login</v-list-tile-content>
				</v-list-tile>
			</v-list>
		</v-navigation-drawer>
		<v-toolbar color="toolbar" app height="98">
			<v-spacer class="hidden-sm-and-down"></v-spacer>
			<v-toolbar-side-icon @click="drawer = !drawer" class="hidden-md-and-up"></v-toolbar-side-icon>
			<img height="50" src="/static/images/logo.svg" class="hidden-sm-and-down" />
			<img height="40" src="/static/images/logo.svg" class="hidden-md-and-up" />
			<v-spacer></v-spacer>
			<v-toolbar-items class="hidden-sm-and-down">
				<v-btn v-for="(item, i) in menu" :key="i" flat @click="goTo(item.goTo)">{{item.label}}</v-btn>
			</v-toolbar-items>
			<v-btn
				round
				color="primary"
				class="hidden-sm-and-down"
				href="https://app.vendergas.com.br/login"
				target="_blank"
			>Login</v-btn>
			<v-spacer class="hidden-sm-and-down"></v-spacer>
		</v-toolbar>
		<v-content>
			<vg-conheca ref="conheca"></vg-conheca>
			<vg-sistema ref="sistema"></vg-sistema>
			<vg-aplicativo ref="aplicativo"></vg-aplicativo>
			<vg-diferenciais ref="diferenciais"></vg-diferenciais>
			<vg-contato ref="contato"></vg-contato>
		</v-content>
		<v-divider></v-divider>
		<v-footer color="footer" height="auto" dark class="text-xs-center">
			<v-container>
				Todos os direitos reservados<br>
				&copy; Vendergás Soluções em Tecnologia {{year}}<br>
			</v-container>
		</v-footer>

		<v-btn fab bottom right fixed color="green" dark :style="$vuetify.breakpoint.mdAndUp ? 'width: 100px; height: 100px;' : null" :href="`https://api.whatsapp.com/send?phone=5561998911670&text=Tenho%20uma%20revenda%20de%20gás%20e%20água%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20sistema!`" :large="!$vuetify.breakpoint.mdAndUp" target="_blank">
			<v-icon :size="$vuetify.breakpoint.mdAndUp ? 70 : null" :large="!$vuetify.breakpoint.mdAndUp">mdi-whatsapp</v-icon>
		</v-btn>
	</v-app>
</template>

<style>
.justificado {
	text-align: justify;
}
.green-btn {
	max-width: 600px
}
.green-btn:hover {
	color: #5031a2 !important;
	background: #E0F2F1 !important;
}
</style>

<script>
export default {
	mounted() {
		if (process.env.NODE_ENV == 'development') {
			document.title = 'VENDERGAS - DEV'
		}
	},
	data() {
		return {
			drawer: false,
			menu: [
				{ label: 'Conheça', goTo: 'conheca' },
				{ label: 'O Sistema', goTo: 'sistema' },
				{ label: 'Aplicativo', goTo: 'aplicativo' },
				{ label: 'Diferenciais', goTo: 'diferenciais' },
				{ label: 'Contato', goTo: 'contato' }
			]
		}
	},
	methods: {
		goTo(ref) {
			this.$vuetify.goTo(this.$refs[ref])
			this.drawer = false
		}
	},
	computed: {
		year() {
			return new Date().getFullYear()
		}
	}
}
</script>

