<template>
	<div>
		<v-img src="/images/bg-conheca.png" class="white--text" height="700">
			<v-container grid-list-xl>
				<vg-titulo light>CONTATO</vg-titulo>
				<v-layout row wrap>
					<v-flex xs12 md6>
						<div class="title mb-3">DEIXE SEU CONTATO, QUE ENTRAREMOS EM CONTATO COM VOCÊ</div>
						<v-form ref="form">
							<v-text-field solo label="Nome Completo" v-model="nome" :rules="[rules.required]"></v-text-field>
							<v-text-field solo label="E-mail" v-model="email" :rules="[rules.required, rules.email]"></v-text-field>
							<v-text-field solo label="Telefone" mask="(##) #########" v-model="telefone" :rules="[rules.required]"></v-text-field>
							<v-textarea solo label="Mensagem" v-model="mensagem" :rules="[rules.required, rules.maxLength]" counter="500"></v-textarea>
						</v-form>
						<v-divider dark></v-divider>
						<v-layout justify-center>
							<v-btn round large class="texto--text mb-5" @click="enviar" :loading="loading">Enviar</v-btn>
						</v-layout>
					</v-flex>
					<v-flex xs12 md6>
						<v-layout row wrap fill-height>
							<v-flex xs12>
								<p class="headline">O software para revenda de gás e água feito para você. Entre em contato agora mesmo e conheça o aplicativo e o sistema VENDERGÁS.</p>
							</v-flex>
							<v-flex xs12>
								<v-layout column fill-height justify-end>
									<p class="headline">
										<v-icon color="white" large>mdi-whatsapp</v-icon>&nbsp;(61) 99891-1670
									</p>
									<p class="headline">
										<v-icon color="white" large>mdi-phone</v-icon>&nbsp;(61) 2017-5829
									</p>
									<p class="headline">
										<v-icon color="white" large>mdi-email</v-icon>&nbsp;jaqueline.portela@vendergas.com.br
									</p>
									<p class="headline">
										<v-btn fab flat href="https://www.instagram.com/software.vendergas?igsh=dHQ0NHRyaDljMjFp" target="_blank">
											<v-icon large color="white">mdi-instagram</v-icon>
										</v-btn>
										<v-btn fab flat href="https://www.facebook.com/Venderg%C3%A1s-364317427624441" target="_blank">
											<v-icon large color="white">mdi-facebook-box</v-icon>
										</v-btn>
									</p>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</v-container>
		</v-img>
		<v-snackbar :value="!!snackbar" :color="error ? 'error' : 'success'">
			{{snackbar}}
			<v-btn icon @click="snackbar = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-snackbar>
	</div>
</template>

<script>
import axios from 'axios'
import validator from 'validator'

export default {
	data() {
		return {
			nome: process.env.NODE_ENV == 'development' ? 'Samuel Novaes' : '',
			email: process.env.NODE_ENV == 'development' ? 'samuel.novaes.96@gmail.com' : '',
			telefone: process.env.NODE_ENV == 'development' ? '(61) 983414184' : '',
			mensagem: process.env.NODE_ENV == 'development' ? 'Hello World!' : '',
			loading: false,
			snackbar: false,
			error: false,
			rules: {
				required: v => !!v || 'Campo obrigatório',
				email: v => (!!v && validator.isEmail(v)) || 'Email inválido',
				maxLength: v => (!!v && v.length <= 500) || 'Máximo 500 caracteres!'
			}
		}
	},
	methods: {
		enviar() {
			if (this.$refs.form.validate()) {
				const endpoint = process.env.NODE_ENV == 'production' ? 'https://api.vendergas.com.br' : 'http://localhost:3000'
				this.loading = true
				axios.post(`${endpoint}/contato`, {
					nome: this.nome,
					email: this.email,
					telefone: this.telefone,
					mensagem: this.mensagem
				}).then(() => {
					this.$refs.form.reset()
					this.snackbar = 'Mensagem enviada com sucesso'
					this.error = false
				}).catch((err) => {
					this.snackbar = err.response ? err.response.data : err.message
					this.error = true
				}).finally(() => {
					this.loading = false
				})
			}
		},
		formatPhone(phone) {
			if (!phone) return null;
			const onlyNumber = phone.replace(/[^\d]/g, '');
			if (onlyNumber.length < 8) return null;
			const ddd = onlyNumber.length > 9 ? onlyNumber.substr(0, 2) : '61';
			const eightDigits = onlyNumber.replace(/^\d+(\d{8})$/, '$1');
			const withNine = parseInt(eightDigits[0]) >= 6 ? '9' + eightDigits : eightDigits;
			return ddd + withNine;
		}
	}
}
</script>