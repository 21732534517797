import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import vgConheca from './components/conheca.vue'
import vgSistema from './components/sistema.vue'
import vgDiferenciais from './components/diferenciais.vue'
import vgContato from './components/contato.vue'
import vgAplicativo from './components/aplicativo.vue'
import vgTitulo from './components/titulo.vue'

Vue.use(Vuetify, {
	theme: {
		texto: '#745DAC',
		toolbar: '#ffffff',
		primary: '#b675f9',
		accent: '#39d4b9',
		bgDark: '#5031A2',
		footer: '#48399C'
	}
})

Vue.filter('caixaAlta', v => v.toUpperCase())

Vue.component('vg-conheca', vgConheca)
Vue.component('vg-sistema', vgSistema)
Vue.component('vg-diferenciais', vgDiferenciais)
Vue.component('vg-contato', vgContato)
Vue.component('vg-aplicativo', vgAplicativo)
Vue.component('vg-titulo', vgTitulo)

new Vue({
	el: '#app',
	render: h => h(App)
})